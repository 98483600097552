@import 'app';

body{
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}
.verify-section{
    background-image: url(/../../img/frontend/bg_main.png);
    min-height: 100vh;
}
.verify {
    width: 55%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #444343;
    color: white;
    padding-top: 20px;
    max-width: 580px;
    background: #171717;

    @media(max-width:992px) {
        width: 75%;
    }
    @media(max-width:768px) {
        width: 95%;
    }
    .logo {
        width: 25%;
        margin-bottom: 14px;
        margin-top: 10px;
    }
    .text {
        line-height: 30px;
        margin-bottom: 30px;
        margin-top: 10px;
        color: #fff;
        font-size: 20px;
        font-weight: 300;
        line-height: 27px;

        @media(max-width:768px) {
            font-size: 15px;
            line-height: 20px;
        }
    }
    #email {
        width: 65%;
        display: inline-block;
        border-radius: 0;
        height: 45px;
        margin-bottom: 30px;
        background: #3f3f3f;
        color: #fff;
        border-color: #3f3f3f;
        &:focus{
            outline: 0;
            box-shadow: none;
        }
        @media(max-width:768px) {
            height: 40px;
        }
    }    
    .buttons {
        overflow: hidden;
        border-top: 2px solid #ec0f58;
        padding: 20px;
        .agree {
            float: left;
            background: transparent;
            color: #fff;
            border: 2px solid #ec0f58;
            width: 140px;
            font-family: "Saira Extra Condensed", sans-serif;
            font-weight: 400;
            font-size: 21px;
            &:hover{
                background: #ec0f58;
                color: #fff;
                border: 2px solid #ec0f58;
            }
            &:focus{
                box-shadow: none;
            }
            @media(max-width:768px) {
                width: 85px;
                font-size: 18px;
                padding: 9px 10px 9px;
            }
        }
        .leave {
            float: right;
            background: transparent;
            color: #fff;
            border: 2px solid #ccc;
            width: 140px;
            font-family: "Saira Extra Condensed", sans-serif;
            font-weight: 400;
            font-size: 21px;
            &:hover{
                background: #9a9a9a;
                color: #000;
                border: 2px solid #9a9a9a;
            }
            &:focus{
                box-shadow: none;
            }
            @media(max-width:768px) {
                width: 85px;
                font-size: 18px;
                padding: 9px 10px 9px;
            }
        }
    }
}