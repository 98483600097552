// Plugins

// FontAwesome 5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";


// Header Styles
.read-only, .logged-in-as {
    margin: 0 !important;
    border-radius: 0 !important;
}

.btn-standard{
    background: transparent;
    border: 2px solid #fff;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    text-transform: uppercase;
    padding: 10px 10px 10px;
    width: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    &:hover{
        background: #fff;
        color: #3f3f3f;
    }
}



.square-image {
    border-radius:10px;
    width: 100%;
    padding-top: 100%;
    position: relative;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}