// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fonts
/*@import url('https://fonts.googleapis.com/css?family=Nunito');*/
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Saira+Extra+Condensed:200,300,400&display=swap');

@import "../global";

// Bootstrap Overrides
@import 'variables';

// Pages
/*@import 'pages/dashboard';*/